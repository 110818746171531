
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";
import router from "@/router";
import ContactTypesSelect from "@/components/catalogs-select/ContactTypesSelect.vue";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "Contacts",
  components: {
    EntitySelect,
    ContactTypesSelect,
    CardBasicNew,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const search = ref("");
    const temp = localStorage.getItem("user") as any;
    const user = JSON.parse(temp);
    const contactsTypeSelected = ref([]);
    const entitySelected = ref([]);

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "contacts type: create"
        ) !== -1
      );
    });
    const canSeeEntities = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "entities: index"
        ) !== -1
      );
    });
    const canSeeDetails = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "contacts type: show") !==
        -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "contacts type: update"
        ) !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "contacts type: delete"
        ) !== -1
      );
    });

    const totalFiles = computed(() => store.getters.TotalFiles);
    const dataList = ref([]);
    const filtered = ref([]);
    const viewType = ref(user.user_preferences.view_type_items);
    const originalData = ref([]);
    const created_date = ref([]);
    const otSelected = ref("");
    const form = ref(false);
    const showDelete = ref(false);
    const cantDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.user_preferences.items_per_page,
      total: totalFiles.value,
      last_page: Math.floor(totalFiles.value / 12),
    });
    const queryData = ref({
      entityTypes: [],
      office: [],
      entity: [],
    });

    const header = ref([
      { column: t("iname"), key: "name" },
      { column: t("ilast_name"), key: "last_name" },
      { column: t("iidentification"), key: "identification" },
    ]);

    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "danger" as never,
      } as never,
    ]);

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const createFieldItems = (data) => {
      return data.map(function (element) {
        element.items = {
          iname: element.name,
          ilast_name: element.last_name,
          iidentification: element.identification,
        };
        return element;
      });
    };

    const getItems = async () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/contacts`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          order_by_id: "DESC",
          name: search.value,
          entity_id:
            entitySelected.value.length > 0 ? entitySelected.value : [],
          contact_type_id:
            contactsTypeSelected.value.length > 0
              ? contactsTypeSelected.value
              : [],
        },
      }).then(({ data }) => {
        originalData.value = data.contacts;
        dataList.value = createFieldItems(data.contacts);
        pagination.value.total = data.pagination.total;
        pagination.value.last_page = data.pagination.last_page;
        filtered.value =
          viewType.value === "card"
            ? data.contacts
            : createTableItems(data.contacts);
        onSearch();
        store.commit("setLoadingStatus", false);
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("contacts", [
        { route: "/entities", label: "entities" },
        { route: "/contacts", label: "Contacts" },
      ]);
      getItems();
      store.commit("removeEDataPaymentTypeDetails");
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      getItems();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getItems();
    };

    const onSearch = () => {
      let data = createFieldItems(dataList.value);
      if (viewType.value == "table") {
        data = createTableItems(dataList.value);
      }
      filtered.value = data.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
      if (!filtered.value.length) {
        getItems();
      }
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          name: element.name,
          last_name: element.last_name,
          identification: element.identification,
        });
      });
      return result;
    };

    const seeDetails = (contact) => {
      router.push(`/contact/${contact["id"]}/general`);
    };

    const reload = () => {
      getItems();
      form.value = false;
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push(`/contact/${param1}/general`);
      }
      if (param2 === "REMOVE") {
        deleteDialog(param1);
      }
    };

    const createElement = () => {
      router.push({ path: "/contact/add/general" });
    };

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      store.commit("setLoading", false);
      store.commit("setLoadingStatus", true);
      ApiService.delete(
        `/api/contacts/${deleteId.value}?force_delete=true`
      ).then(({ data }) => {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        getItems();
      });
    };

    const addContactType = (event) => {
      contactsTypeSelected.value = event;
    };
    const addEntity = (data) => {
      entitySelected.value = data;
    };

    return {
      canCreate,
      canSeeEntities,
      canSeeDetails,
      canEdit,
      canDelete,
      created_date,
      filtered,
      dataList,
      search,
      viewType,
      header,
      tableButtons,
      pagination,
      form,
      otSelected,
      showDelete,
      cantDelete,
      queryData,
      contactsTypeSelected,
      entitySelected,
      formatDate,
      addContactType,
      addEntity,
      onSearch,
      getItems,
      currentPageChange,
      setItemsPerPage,
      seeDetails,
      reload,
      actionsButton,
      createElement,
      deleteDialog,
      deleteElement,
      user,
    };
  },
});
