
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "ContactTypesSelect",
  props: {
    contact_type_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:contact_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputContactType = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isContactRequired = (value) => {
      if (props.required && !value) {
        return t("rcontacttype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "contact",
      isContactRequired
    );

    const getContactType = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/contact-types/lists").then(({ data }) => {
        inputContactType.value.list = data.contact_types;
        inputContactType.value.options = data.contact_types;
      });
    };
    const selectContactType = (query) => {
      if (query !== "") {
        store.commit("setLoading", false);
        ApiService.query(`/api/contact-types/lists`, {
          params: { per_page: 25, name: query },
        }).then(({ data }) => {
          inputContactType.value.list = data.contact_types;
          inputContactType.value.options = data.contact_types;
        });
      } else {
        inputContactType.value.options = [];
      }
    };

    watch(
      () => props.contact_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => getContactType());

    return {
      element_id,
      inputContactType,
      errorMessage,
      getContactType,
      selectContactType,
    };
  },
};
